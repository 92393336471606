<template>
  <div>
    <ys-detail :icon="detailIcon" :loading="loading" :name="detail.iname">
      <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('专项任务状态') }}</Tag>
      <template slot="brief">
        <ys-cell title="发布开始" :label="detail.publishAt|formatDate"/>
        <ys-cell title="完成期限" :label="detail.finishAt|formatDate"/>
        <ys-cell title="任务描述" :label="detail.remarks"/>
        <ys-cell title="项目名称" :label="detail.project?detail.project.iname:''"/>
      </template>
      <template slot="main-left">
        <Tabs type="card" v-model="tab" :key="detail.id">
          <Tab-pane :label="(detail.type===9?'风险':'隐患')+'列表'" icon="md-list-box" name="1" v-if="detail.type<3||detail.type===9">
            <problems :checkType="detail.type" :tableHeight="pageListHeight" :midData="[detail]" :mid="detailID" v-if="tab==='1'&&detail.type!==9"></problems>
            <risk :checkType="detail.type" :tableHeight="pageListHeight" :midData="[detail]" :mid="detailID" v-if="tab==='1'&&detail.type===9"></risk>
          </Tab-pane>
          <Tab-pane label="处理专家" icon="ios-contacts" name="2">
            <check-user-list :tableHeight="pageListHeight" :midData="[detail]" :type="1" :mid="detailID" v-if="tab==='2'"></check-user-list>
          </Tab-pane>
          <Tab-pane label="陪同业务员" icon="ios-contact" name="3" v-if="detail.type<3">
            <check-user-list :tableHeight="pageListHeight+58" :midData="[detail]" :type="2" :mid="detailID" v-if="tab==='3'"></check-user-list>
          </Tab-pane>
          <Tab-pane label="参与人员" icon="ios-contacts" name="9" v-if="detail.type>2&&detail.type!==9&&detail.type!==7">
            <members :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='9'"></members>
          </Tab-pane>
          <Tab-pane label="检查项目" icon="ios-browsers" name="4" v-if="detail.type<3">
            <checkList :mid="detailID" v-if="tab==='4'"></checkList>
          </Tab-pane>
          <Tab-pane label="检查资料" name="5" icon="md-list-box" v-if="detail.hasDoc === 1&&detail.type<3">
            <Inspection :tableHeight="pageListHeight" :mid="detailID" :midData="[detail]" v-if="tab==='5'"></Inspection>
          </Tab-pane>
          <Tab-pane label="检查附件" icon="md-document" name="6">
            <ys-resource :resourceType="2" :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='6'"></ys-resource>
          </Tab-pane>
          <Tab-pane label="风险回头看" icon="md-list-box" name="7" v-if="detail.type<3">
            <rectificationProblem :tableHeight="pageListHeight" :projectId="detail.projectId" v-if="tab==='7'"></rectificationProblem>
          </Tab-pane>
          <Tab-pane label="超危/危大工程" name="8" icon="md-list-box" v-if="detail.hasDanger === 1&&detail.type<3">
            <dangerous-engineering :tableHeight="pageListHeight" :mid="detailID" :midData="[detail]" v-if="tab==='8'"></dangerous-engineering>
          </Tab-pane>
        </Tabs>
      </template>
      <template slot="main-right">
        <template v-if="detail.type===1">
          <h1 class="h1Title">风险分析</h1>
          <chart :key="detail.id" :mid="detail.id"></chart>
        </template>
        <template v-else>
          <ys-empty title="暂未开放"></ys-empty>
        </template>
      </template>
    </ys-detail>
    <ys-modal v-model="problemFlag" :width="1200" title="新增风险">
      <problem-add @on-ok="getDetail" :parentID="detailID" :midData="[detail]"></problem-add>
    </ys-modal>
    <ys-modal v-model="addReportFlag" :width="800" :title="detail.reportId ? '修改报告' : '创建报告'">
      <ReportAdd @on-ok="getDetail" :midData="[detail]" :checkId="detailID"></ReportAdd>
    </ys-modal>
    <ys-modal v-model="modShow" :width="1000" title="生成整改单">
      <rectifications-edit @on-ok="getDetail" :parentID="detailID" taskType="2"></rectifications-edit>
    </ys-modal>
    <ys-modal v-model="checkItemFlag" :width="800" title="调整检查项">
      <categoryEdit :mid="detailID"></categoryEdit>
    </ys-modal>
  </div>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {checkInfo,} from "@/api/check";
import problems from "@/views/problems/index";
import CheckUserList from "@/views/task/detail/checkUserList.vue";
import members from "@/views/task/detail/members/index.vue";
import checkList from "@/views/task/detail/checkList.vue";
import Chart from "@/views/task/detail/chart";
import ProblemAdd from "@/views/problems/Edit";
import risk from "@/views/service/identification/risk";
import ReportAdd from "@/views/report/addReport";
import RectificationsEdit from "@/views/rectifications/Edit";
import Inspection from "@/views/task/detail/Inspection/index.vue"
import categoryEdit from "@/views/task/categoryEdit";
import rectificationProblem from "@/views/rectifications/detail/problem";
import dangerousEngineering from "@/views/task/detail/dangerousEngineering/index.vue";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {
    rectificationProblem,
    RectificationsEdit,
    members,
    risk,
    ReportAdd,
    ProblemAdd,
    Chart,
    CheckUserList,
    problems,
    checkList,
    Inspection,
    categoryEdit,
    dangerousEngineering
  },
  data() {
    return {
      addReportFlag: false,
      modShow: false,
      problemFlag: false,
      loading: false,
      checkItemFlag: false
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.detail = {}
      checkInfo({id: this.detailID}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
          this.$nextTick(() => {
            this.tab = this.detail.type === 9 || this.detail.type < 3 ? '1' : '2';
          })
        }
        this.setTagName(this.detail.iname)
      }).catch(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>